.button-manual {
  position: absolute;
  right: 200px;
  bottom: 100px;
  background-color: #FFAD84;
  margin: 12px;
  font-size: 18px;
  border: none;
}

.button-manual:hover {
  background-color: #FFC090;
}

.how-many-words-button {
  margin: 12px;
  font-size: 24px;
  background-color: #FFAD84;
  border: none;
  font-weight: bold;
}

.how-many-words-button:hover {
  background-color: #FFC090;
}

.how-many-words-text {
  color: white;
  font-size: 32px;
  font-weight: bold;
}

.player {
  color: white;
  font-size: 80px;
  font-weight: bold;
  text-shadow: 5px 5px 5px #FFAD84;
}

.category-button {
  margin: 6px;
  font-size: 20px;
  background-color: #FFAD84;
  border: none;
}

.forbidden-words {
  margin-right: 16px;
  margin-bottom: 6px;
}

.player-button {
  margin: 16px;
  font-size: 26px;
  background-color: #FFAD84;
  border: none;
}

.player-button:hover {
  background-color: #FFC090;

}

.category-button:hover {
  background-color: #FFC090;
}

.categories {
  padding: 20px;
  width: 800px;
}

.main-word {
  font-size: 50px;
  font-weight: bold;
  color: white;
  margin: 16px;
  width: auto;
}

.forbidden-words {
  font-size: 20px;
  color: white;
}

@media screen and (max-width: 1024px) {
  .button-manual {
    right: 20px;
    bottom: 20px;
  }
  .categories {
    width: 400px;
  }
  .main-word{
    font-size: 30px;
  }
  .forbidden-words{
    font-size: 18px;
    text-align: center;
    justify-content: center;
    margin: 6px;
  }

  .player-button{
    font-size: 20px;
    margin: 6px;
  }
}
