.language-container {
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.language-button {
  margin: 12px;
  width: 150px;
  height: 60px;
  font-size: 32px;
  background-color: #FFAD84;
  border: none;
}

.language-button:hover {
  background-color: #FFC090;
}
