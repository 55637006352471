@import "~bootstrap/scss/bootstrap";

body {
  background: url("https://public.andret.eu/.szop-gracz/limonki.png");
  background-size: cover;
  background-blend-mode: darken;

  .custom-container {
    background-color: #FFAD84;
    padding: 20px;
    border-radius: 25px;
    width: 60%;
  }

  .container-main {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh;
    display: flex;
  }

}
