.manual-text{
  color: white;
}

.headline{
  color: white;
  font-weight: bold;
}

.modal-manual{
  background-color: darkolivegreen;
}
