.result-text {
  font-size: 36px;
  margin-bottom: 30px;
  color: white;
}

.result-container {
  display: inline-grid;
  width: 340px!important;
}

.players {
  font-size: 28px;
  color: white;
  float: left;
}

.score {
  font-size: 28px;
  color: white;
  float: right;
}

.result-button{
  margin: 6px;
  font-size: 20px;
  background-color: #FFAD84;
  border: none;
}

.result-button:hover{
  background-color: #FFC090;
}
