.custom-input {
  width: 320px;
  justify-content: center;
  text-align: center;
}

.playerList-button {
  margin: 12px;
  width: 160px;
  height: 40px;
  font-size: 18px;
  background-color: #FFAD84;
  border: none;
}

.playerList-button:hover {
  background-color: #FFC090;
}

.playerList-button:disabled {
  opacity: 0.7;
  background-color: #FFAD84;
}

.playerList-container {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

@media screen and (max-width: 1024px) {

  .custom-input {
    align-content: center;
    justify-content: center;
  }

}
